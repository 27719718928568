let CommodityCategory = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;

    this.Categories = function (success, error) {
        var url = ServiceBaseAddress + '/api/CommodityCategory/Shop';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    this.Add = function (category, success, error) {
        var url;
        if (Array.isArray(category)) {
            url = ServiceBaseAddress + '/api/CommodityCategory/Manager/All';
        }
        else if (typeof category === 'object') {
            url = ServiceBaseAddress + '/api/CommodityCategory/Manager';
        }
        if (url) {
            TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, category,
                function (data) {
                    if (success) {
                        var jsonObj = JSON.parse(data);
                        success(jsonObj);
                    }
                }, error);
        }
        else if (error) {
            error('param error');
        }
    }

    this.Edit = function (category, success, error) {
        if (category) {
            var url = ServiceBaseAddress + '/api/CommodityCategory/Manager';
            TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, category,
                function (data) {
                    if (success) {
                        var jsonObj = JSON.parse(data);
                        success(jsonObj);
                    }
                }, error);
        }
    }

    this.Remove = function (categoryId, success, error) {
        var url = ServiceBaseAddress + '/api/CommodityCategory/Manager/' + categoryId;
        TokenClient.Delete(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    this.GetParameter = function (categoryId, success, error) {
        var url = ServiceBaseAddress + '/api/CommodityCategory/Manager/Parameter/'+ categoryId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    this.AddParameter = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/CommodityCategory/Manager/Parameter';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    this.CommonParameter = function (categoryId, success, error) {
        var url = ServiceBaseAddress + '/api/CommodityCategory/Parameter/'+ categoryId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}

export { CommodityCategory }
